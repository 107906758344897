import { Trans, useTranslation } from 'react-i18next'
import './Parteners.scss'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Link } from 'react-router-dom'

export default function Parteners() {
  const { t } = useTranslation()

  const Parteners = [
    {
      url: '/imgs/cisco_logo.svg',
      alt: 'Cisco',
    },
    {
      url: '/imgs/mozaik_logo.svg',
      alt: 'Mozaik',
    },
    {
      url: '/imgs/randstad_logo.svg',
      alt: 'Randstad',
    },
    {
      url: '/imgs/plaine_logo.svg',
      alt: 'Plaine commune',
    },

    {
      url: '/imgs/ileDeFrance_logo.png',
      alt: 'ile de france',
    },

    {
      url: '/imgs/logotel_logo.svg',
      alt: 'Logotel',
    }


  ]

  return (
    <section className="section section-partenaires">
      <div className="section_container">
        <h2 className="section_titleBottom  c-pink display-TitleSection">
          <Trans i18nKey="key">{t('partners.title')}</Trans>
        </h2>
        <div className="row row-parteners">
          {Parteners.map((partner) => (
            <div
              key={partner.alt}
              className="row_col row_col-parteners parteners"
            >
              <img
                alt={partner.alt}
                className="parteners_img"
                src={partner.url}
              />
            </div>
          ))}
        </div>

        {/* <Link
          rel="noreferrer"
          to={'partners'}
          className={'card_btn btn btn-primary header_txt-btn'}
        >
          {t('more')}
          <ArrowForwardRoundedIcon />{' '}
        </Link> */}
      </div>
    </section>
  )
}
