import ReactGA from 'react-ga4'
import { useLayoutEffect } from 'react'
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom'
import './App.scss'
import Routing from './Routes/Routing'
import CookieConsent, { Cookies } from 'react-cookie-consent'

import BackToTopButton from './Components/ScrollButton/ScrollButton'
import { BaseContextProvider, useBaseContext } from './Contexts/base-context'
import { useTranslation } from 'react-i18next'

export default function App() {
  const { isBanner } = useBaseContext()
  const { t } = useTranslation()
  

  const handleAccept = () => {
    const TRACKING_ID = process.env.REACT_APP_TRACKING_ID
    TRACKING_ID && ReactGA.initialize(TRACKING_ID)
  }

  const handleDecline = () => {
    Cookies.remove('_ga')
    Cookies.remove('_ga_TQQ8F10260')

    Cookies.remove('_ga', { path: '/', domain: '.levelup93.fr', secure: true })
    Cookies.remove('_ga_TQQ8F10260', {
      path: '/',
      domain: '.levelup93.fr',
      secure: true,
    })

    Cookies.remove('_ga', {
      path: '/',
      domain: 'www.levelup93.fr',
      secure: true,
    })
    Cookies.remove('_ga_TQQ8F10260', {
      path: '/',
      domain: 'www.levelup93.fr',
      secure: true,
    })

    Cookies.remove('_ga', { path: '/', domain: 'localhost', secure: true })
    Cookies.remove('_ga_TQQ8F10260', {
      path: '/',
      domain: 'localhost',
      secure: true,
    })
  }

  const Wrapper: React.FC<any> = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
      if (location.pathname !== '/') {
        document.documentElement.scrollTo(0, 0)
      }
    }, [location.pathname])
    return children
  }

  return (
    <>
      <BaseContextProvider>
        <BackToTopButton />
        <HashRouter basename='/'>
          <Wrapper>
            <Routing />
          </Wrapper>
        </HashRouter>
        {isBanner === true && (
          <CookieConsent
            onAccept={handleAccept}
            onDecline={handleDecline}
            disableStyles={true}
            enableDeclineButton
            location="bottom"
            buttonText={t('yes')}
            declineButtonText={t('no')}
            declineButtonClasses="btn btn-simple"
            buttonClasses="btn btn-primary"
            containerClasses="banner"
            contentClasses="text-capitalize banner_txt"
            expires={182}
          >
            {t('cookies.BannerTxt')}
          </CookieConsent>
        )}
      </BaseContextProvider>
    </>
  )
}
