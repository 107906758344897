import { Trans, useTranslation } from 'react-i18next'
import './Steps.scss'

export default function Steps() {
  const { t } = useTranslation()

  const Steps: any = t('steps.allSteps', { returnObjects: true })

  return (
    <section className="section section-steps">
      <div className="section_container">
        <h2 className="section_titleBottom c-pink display-TitleSection">
          <Trans i18nKey="key">{t('steps.title')}</Trans>
        </h2>
        <div className="row row-steps">
          {Steps.map((step: {[key: string]: any} ) => (
            <div key={step.title} className="row_col step">
              <header className="step_header">
                <img alt="test" className="step_header-img" src={step.url} />
              </header>
              <div className="step_body">
                <h3 className="step_body-title c-pink display-txtBold">
                  {step.title}
                </h3>
                <p className="step_body-chapitre c-darkGrey display-suBTxtBold">
                  <strong>{step.chap}</strong>
                </p>
                <p
                  className="step_body-txt c-middleGrey display-Txtlittle"
                  dangerouslySetInnerHTML={{ __html: step.txt }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
