export const EN = {
  translation: {
    home: {
      title: `
        <span class="c-pink">Level</span> 
        <span class="c-blue">Up</span>
        `,
      baseline: 'be there soon !',
      txt1: 'Level Up is a free program, open to everyone to train and find a job in digital.',
      txt2: "No need for a diploma to access it. You don't know anything about IT? It doesn't matter. Level Up is designed to support you whatever your profile and experience. Only your motivation counts. ",
      txt3: 'Get ready!',
    },
    progress: {
      labelTop1: 'Your first<br> training',
      labelTop2: 'You choose<br /> your path',
      labelTop3: "You're learning<br/> your new job",
      labelTop4: "You're ready<br />to get started",
      toi: 'You',
      nous: 'We',
      circle1: 'Winter<br>2024',
      circle2: 'PRINTEMPS<br>2024',
      circle3: 'SUMMER<br>2024',
      circle4: 'AUTUMN<br>2024',
      labelBottom1:
        'We prepare the platform, we support you in your first steps',
      labelBottom2: `<span class="progress_nous_txt"><span class="c-blue t-bold">Launch of the platform</span><br>We guide you and help you design your journey</span >`,
      labelBottom3:
        'We give you access to a program that will make you a specialist',
      labelBottom4:
        'We organize the meet-ups and prepare your interview together',
    },
    steps: {
      title: 'What is <strong>Level Up?',
      allSteps: [
        {
          title: 'Advice',
          chap: `Don't know where to start?`,
          txt: `The world of computing
                 is a labyrinth where it is
                 easy to get lost.
                 Level Up offers you <strong class='t-bold'>an assessment</strong>
                 to help you find your
                 path.`,
          url: '/imgs/icon_conseils.svg',
        },
        {
          title: 'Training',
          chap: 'With you every step',
          txt: `You are not born an expert, with Level Up, you become one. The training courses are accessible at any time, from a computer or smartphone. Our coaches and professional trainers support you from <strong class='t-bold'>A to Z.</strong>`,
          url: '/imgs/icon_formations.svg',
        },
        {
          title: 'A profession',
          chap: 'Job offers for you',
          txt: `<strong class='t-bold'>You apply directly</strong> on Level Up to find a company that needs
                 skills you have acquired.
                 It's a win-win.`,
          url: '/imgs/icon_metier.svg',
        },
      ],
    },
    try: {
      tries: [
        {
          title: `Introduction to Cybersecurity`,
          txt: `A 6-hour course* that will teach you the basics of the world of Cybersecurity. Your first step towards a position in Cyber!`,
          link: 'https://skillsforall.com/course/introduction-to-cybersecurity?courseLang=fr-FR&instance_id=64dc68bb-5a99-4ca9-b39b-3b7ce8febb2a',
        },
        {
          title: `Introduction to IoT and Digital Transformation`,
          txt: `A 6-hour course* to understand the digital world, and how it transforms the professional world and our everyday lives.`,
          link: 'https://skillsforall.com/course/introduction-iot?courseLang=fr-FR&instance_id=5a70cd5c-576c-4d66-887a-6548d28eac1a',
        },
        {
          title: `Networking Basics`,
          txt: `Ce cours couvre les bases de la mise en réseau et des dispositifs médias et protocoles de réseau.`,
          link: 'https://skillsforall.com/fr/course/networking-basics?courseLang=fr-FR',
        },
      ],

      title: 'Want to try?',
      baseline: `Find out now what awaits you with these first two lessons:`,
      btn: 'Begin',

      credits: '*Duration may vary from one user to another.',
    },
    coursesv2:{
      title: `How it<br/> works`,
      courses: [
        {
          number: '1',
          image: '/imgs/coursesV2/courses1.jpg',
          alt: '',
          text: `Click on the course of your choice: <a class="c-blue" target="_blank" href="https://skillsforall.com/course/introduction-to-cybersecurity?courseLang=en-US&instance_id=64dc68bb-5a99-4ca9-b39b-3b7ce8febb2a">Course 1</a>, <a class="c-blue" target="_blank" href="https://skillsforall.com/course/introduction-iot?courseLang=en-US&instance_id=5a70cd5c-576c-4d66-887a-6548d28eac1a">Course 2</a> or <a class="c-blue" target="_blank" href="https://skillsforall.com/course/networking-basics?courseLang=en-US&instance_id=64dc68bb-5a99-4ca9-b39b-3b7ce8febb2a">Course 3</a>.`
        },
        {
          number: '2',
          image: '/imgs/coursesV2/courses2.jpg',
          alt: '',
          text: `Complete one and a half courses. We're sending you an invitation to connect to Level Up.`
        },
        {
          number: '3',
          image: '/imgs/coursesV2/courses3.jpg',
          alt: '',
          text: `Create your account on the platform. We validate your profile <span class="c-blue">and Level Up is yours!</span>`
        },
      
      ]

    },
    partners: {
      title: 'In collaboration with',
    },
    footer: {
      links: [
        // {
        //   url: '/privacy-policy',
        //   title: 'Privacy policy',
        // },
        {
          url: '/cookies',
          title: 'Cookies',
        },
      ],

      credits: '© 2024 Level Up',
      contact: 'Contact us',
    },

    cookies: {
      BannerTxt: `This website uses anonymous analytics cookies, including third party cookies, to collect information about how users use the site. Use the "Ok" button to agree. To continue without accepting, press the "No" button`,
      back: 'Retour',
      title: 'Cookies',
      txt: `<h2>Cookie Policy</h2>

<p>In accordance with article 13 of Regulation (EU) 2016/679 relating to the processing of personal data (hereinafter "GDPR"), Cisco Systems Italy S.r.l. (hereinafter, "CISCO") informs you of the following information regarding cookies installed on www.levelup93.fr. (hereinafter “Site”). </p>

<h3>Data controller</h3>
<p>
The Data Controller is Cisco Systems Italy S.r.l. - with registered office at Via Vittor Pisani, 20 - 20124 Milan (Italy) (hereinafter the “Data Controller”).
</p>
<h3>
What are cookies ?
</h3>
<p>
Cookies are strings of text created by a server and stored on the computer's hard drive or on any device used by the user to access the Internet (smartphones, tablets), then retransmitted during subsequent accesses by the user to the Internet.
</p>
Cookies allow us to collect information about users' navigation on the site.
<p>
Cookies may be stored permanently on your computer and have a variable lifespan (so-called persistent cookies), but they may also disappear when you close the browser or have a limited duration (session cookies).
</p><p>
Cookies may come from the site you are visiting (first-party cookies) or from other sites (third-party cookies).
</p>
 
<h3>
Cookies used and purposes
</h3>

<p>
Navigation and session cookies are not used on this Site
</p><p>
Anonymous (third-party) analytical cookies
</p><p>
These anonymized third-party cookies, notably from the Google Analytics platform, are used to collect information on the use of the site (number of visitors, pages visited, time spent on the site, etc.)
</p>
<div class="table_wrapper" >
<div class="table_inner">

<table class="table">
<thead>

<tr>
<th>
Cookie
</th>

<th>
Name
</th>

<th>
Purpose
</th>

<th>
Kind
</th>

<th>
Duration
</th>

<th>
Consent
</th>

<th>
Further information
</th>

</tr>
</thead>
<tr>

<td>
Google
</td>

<td>
_ga
</td>

<td>
Used to distinguish users - required by third-party Google Analytics
</td>

<td>
3rd part
</td>

<td>
Persistent (2 years)
</td>

<td>
Not required
</td>

<td rowspan="2">
The privacy policy and how to use Google cookies are available in the following links:

<a href="https://www.google.com/intl/it/policies/privacy/" target="_blank">https://www.google.com/intl/it/policies/privacy/ </ a>

<a target="_blank" href='https://www.google.com/intl/it/policies/technologies/'>https://www.google.com/intl/it/policies/technologies/ </ a>

You can download a Browser plugin to permanently disable Google Analytics at the following link:

<a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=it </a>
</td>

</tr>


<tr>
<td>

</td>

<td>
_gat
</td>

<td>
Allows the acceleration limit to the Google Analytics service.
</td>

<td>

</td>
<td>
Temporary (10 minutes)
</td>

<td>

</td>

</tr>

</table>
</div>
</div>
<h3>
Manage cookie preferences
</h3><p>
When you access a page on the site, a banner containing brief information is displayed.
</p><p>
You can object to the recording of third-party cookies on your hard drive through the link indicated in the table above, while benefiting from access and use of the web pages, while you can object to the first part of saving cookies to your hard drive by configuring your browser to disable cookies. However, after doing this, you will not be able to access the web pages.
</p><p>
Here are the means offered by the main browsers:
</p>

<ul>
<li>
Internet Explorer ( <a href="https://windows.microsoft.com/fr-fr/windows-vista/block-or-allow-cookies" target="_blank" >https://windows.microsoft.com/ fr-fr/windows-vista/block-or-allow-cookies</a>)
</li><li>

Chrome ( <a href="https://support.google.com/chrome/answer/95647?hl=fr&p=cpn_cookies" target="_blank"> https://support.google.com/chrome/answer/95647 ?hl=fr&p=cpn_cookies </a> )
</li><li>
Firefox ( <a href="https://support.mozilla.org/fr/kb/sites-dissent-cookies-bloques-les-debloquer" target="_blank"> https://support.mozilla.org/fr /kb/sites-dissent-cookies-bloques-unblock them</a>)
</li><li>
Opera ( <a href="http://help.opera.com/Windows/10.00/fr/cookies.html" target="_blank">http://help.opera.com/Windows/10.00/fr/cookies .html</a>)
</li><li>
Safari ( <a href="https://support.apple.com/kb/PH19214?viewlocale=fr_IT&locale=fr_US">https://support.apple.com/kb/PH19214?viewlocale=fr_IT&locale=fr_US</a > )
</li></ul>


<p>
Communication and dissemination of data
</p><p>
These data may also be processed by trusted persons who carry out technical and organizational tasks on behalf of the Data Controller or its subcontractor.
</p><p>
The data collected using cookies is not transmitted to third parties and is not disseminated.
</p>

<h2>
Rights of the data subject
</h2>

<p>
As a data subject, in accordance with Articles 15 to 22 of the GDPR, where applicable, you have the right to:
</p>

<ul>
<li>
a) Access your data and request a copy;
</li><li>
b) Request a rectification;
</li><li>
c) Request erasure;
</li><li>
d) Obtain restriction of processing;
</li><li>
e) Object to the processing;
</li><li>
f) Receive in a structured, commonly used and machine-readable format and transmit this data without hindrance to another data controller, where technically feasible.
</li>
</ul>

<p>
Please note that your rights above are limited by law and we must respect them, possibly under certain conditions.
</p><p>
If you wish to exercise any of the rights listed above, please send an email to the following address: levelup9@logotel.it
</p><p>
Without prejudice to any other administrative or judicial recourse, to exercise your right to file a complaint (art. 77 GDPR), you can also contact the CNIL (National Commission for Information Technology and Liberties).
</p>
      `,
      actionTxt: ` <p>
      This site uses cookies to improve the experience
      user. Do you want to be tracked?
    </p>`,
    },
    privacyPolicy: {
      back: 'Back',
      title: 'Privacy policy',
      txt: `
      
        <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste quis
        in nesciunt eos, delectus provident ratione maiores accusamus
        suscipit ipsa earum qui sed voluptas similique sit dolorum tempora
        dignissimos perspiciatis.
      </p>
        `,
    },
    JobPage: [
      {
        title: 'Cybersecurity',
        value: 'cybersecurity',
        tags: ['Présentiel'],

        img: {
          src: '/imgs/jobs/header_cybersecurite.png',
          alt: 'Cybersecurity',
        },

        infos: {
          title: 'What is it about ?',
          txt: `<p class="jobIntro_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna.</p><p class="jobIntro_txt">Sed id rutrum augue. Mauris iaculis augue in sem dictum laoreet eget in erat. Pellentesque vitae rutrum ligula, eu venenatis erat. Cras vitae posuere dui, in tincidunt quam. Fusce ornare metus eget dui auctor consequat. Cras pellentesque convallis scelerisque. Nunc sed metus nisi. Integer malesuada fringilla interdum. Aenean vel ipsum nec est convallis iaculis.</p><p class="jobIntro_txt"><strong>Ut ut augue interdum, fermentum mi sed, gravida tortor. Aenean consectetur blandit purus vel aliquet. Integer accumsan dignissim porta. Proin sed neque et erat gravida pulvinar.</strong></p>`,
        },

        competences: {
          title: 'What skills ?',

          txts: [
            {
              title: 'Hardskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
            {
              title: 'Softskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
          ],
        },

        remuneration: {
          title: 'What remuneration ?',
          max: {
            value: '50 000 €',
            txt: 'maximum',
          },
          min: {
            value: '25 000 €',
            txt: 'minimum',
          },
        },
      },
      {
        title: 'Networking',
        value: 'networking',
        tags: ['Présentiel'],
        img: {
          src: '/imgs/jobs/header_networking.png',
          alt: 'Networking',
        },

        infos: {
          title: 'What is it about ?',
          txt: `<p class="jobIntro_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna.</p><p class="jobIntro_txt">Sed id rutrum augue. Mauris iaculis augue in sem dictum laoreet eget in erat. Pellentesque vitae rutrum ligula, eu venenatis erat. Cras vitae posuere dui, in tincidunt quam. Fusce ornare metus eget dui auctor consequat. Cras pellentesque convallis scelerisque. Nunc sed metus nisi. Integer malesuada fringilla interdum. Aenean vel ipsum nec est convallis iaculis.</p><p class="jobIntro_txt"><strong>Ut ut augue interdum, fermentum mi sed, gravida tortor. Aenean consectetur blandit purus vel aliquet. Integer accumsan dignissim porta. Proin sed neque et erat gravida pulvinar.</strong></p>`,
        },

        competences: {
          title: 'What skills?',

          txts: [
            {
              title: 'Cybersecurity',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
            {
              title: 'Softskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
          ],
        },

        remuneration: {
          title: 'What remuneration ?',
          max: {
            value: '50 000 €',
            txt: 'maximum',
          },
          min: {
            value: '25 000 €',
            txt: 'minimum',
          },
        },
      },

      {
        title: 'Datas',
        value: 'data',
        tags: ['Présentiel'],
        img: {
          src: '/imgs/jobs/header_data.png',
          alt: 'Datas',
        },

        infos: {
          title: 'What is it about ?',
          txt: `<p class="jobIntro_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna.</p><p class="jobIntro_txt">Sed id rutrum augue. Mauris iaculis augue in sem dictum laoreet eget in erat. Pellentesque vitae rutrum ligula, eu venenatis erat. Cras vitae posuere dui, in tincidunt quam. Fusce ornare metus eget dui auctor consequat. Cras pellentesque convallis scelerisque. Nunc sed metus nisi. Integer malesuada fringilla interdum. Aenean vel ipsum nec est convallis iaculis.</p><p class="jobIntro_txt"><strong>Ut ut augue interdum, fermentum mi sed, gravida tortor. Aenean consectetur blandit purus vel aliquet. Integer accumsan dignissim porta. Proin sed neque et erat gravida pulvinar.</strong></p>`,
        },

        competences: {
          title: 'What skills ?',

          txts: [
            {
              title: 'Cybersécurity',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
            {
              title: 'Softskills',
              tooltip: 'add',
              list: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Aliquam nisl turpis',
                'Lorem ipsum dolor sit amet',
                'Aliquam nisl turpis, varius ac lacus vitae',
              ],
            },
          ],
        },

        remuneration: {
          title: 'What remuneration ?',
          max: {
            value: '50 000 €',
            txt: 'maximum',
          },
          min: {
            value: '25 000 €',
            txt: 'minimum',
          },
        },
      },
    ],
    tags: {
      Présentiel: 'Face-to-face',
      Remote: 'Remote',
      Hybride: 'Hybrid',
    },
    partenersPage: {
      title: `Our <span className="c-pink">parteners</span>`,
      list: [
        {
          name: 'Cisco',
          link: {
            txt: 'Visit the Networking Academy >',
            path: 'https://www.netacad.com/',
          },
          img: {
            alt: 'Cisco',
            path: '/imgs/cisco_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },

        {
          name: 'Plaine',
          link: {
            txt: 'Visit the website of la Plaine Commune >',
            path: 'https://plainecommune.fr/',
          },
          img: {
            alt: 'Plaine',
            path: '/imgs/plaine_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },

        {
          name: 'Mozaïk',
          link: {
            txt: 'Visit the website of the fondation Mozaïk >',
            path: 'https://www.fondation-mozaik.org/',
          },
          img: {
            alt: 'Mozaïk',
            path: '/imgs/mozaik_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },
        {
          name: 'Randstad',
          link: {
            txt: 'Visit the website of Randstad >',
            path: 'https://www.randstad.fr/',
          },
          img: {
            alt: 'Randstad',
            path: '/imgs/randstad_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        The Randstad Group was founded in the Netherlands in 1960 and has since established itself as a world leader in the field of human resources, intervening at all stages of professional life: from recruitment to integration, from management to training, and through mobility.​
      </p>`,
        },
        {
          name: 'Logotel',
          link: {
            txt: 'Visit the website of Logotel >',
            path: 'https://www.logotel.it/',
          },
          img: {
            alt: 'Logotel',
            path: '/imgs/logotel_logo.svg',
          },
          txt: `
    
        <p class="listPartners_txt">
        Lorem ipsum, dolor sit amet consectetur adipisicing
        elit. Facilis magni facere blanditiis minima aspernatur
        rem commodi quas dolor sint cumque pariatur eveniet
        totam, necessitatibus accusantium minus nostrum
        voluptas. Dolores, atque.
      </p>
      <p class="listPartners_txt">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Eius iste quae, facilis eum, alias magni adipisci
        placeat est voluptatibus dolore expedita voluptates
        animi, mollitia tenetur odio suscipit temporibus?
        Cumque, eius?
      </p>`,
        },
      ],
    },

    who: {
      title: 'For who ?',
      presentation: {
        title: 'Présentation',
        subtitle: 'Armand',
        txt: `Armand Leblois, responsable européen de la Cisco Networking Academy, te présente Level Up. Toi aussi, rejoins dès maintenant les 400.000 étudiants qui utilisent déjà ce programme en Europe pour se former aux métiers du numérique !`,
        video: {
          name: 'Présentation',
          path: '/videos/ARMAND/video.mp4',
          srt: {
            en: '/videos/ARMAND/srt/en.vtt',
            fr: '/videos/ARMAND/srt/fr.vtt',
          },
          poster: '/videos/ARMAND/poster.png',
          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh.`,
        },
      },

      portrait: {
        title: 'Portrait',
        subtitle: 'Arnaud',
        txt: `Arnaud Burlion is a network engineer and trainer at the Cisco Networking Academy… all this without having passed the baccalaureate. He tells you how the Cisco Networking Academy allowed him to take a new turn in his career.`,

        video: {
          name: 'Arnaud',
          path: '/videos/ARNAUD/video.mp4',
          srt: {
            en: '/videos/ARNAUD/srt/en.vtt',
            fr: '/videos/ARNAUD/srt/fr.vtt',
          },
          poster: '/videos/ARNAUD/poster.png',
          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh.`,
        },
      },
    },

    temoignages: {
      title: 'Testimonials',

      videos: [

        {
          name: 'Mohamed Amine',
          path: '/videos/MOHAMED-AMINE/video.mp4',
          srt: {
            en: '/videos/MOHAMED-AMINE/srt/en.vtt',
            fr: '/videos/MOHAMED-AMINE/srt/fr.vtt',
          },
          poster: '/videos/MOHAMED-AMINE/poster.jpg',
          details: `It is a platform that allows access to courses in complete autonomy, while offering support.`,
        },
        {
          name: 'Choukry',
          path: '/videos/CHOUKRY/video.mp4',
          srt: {
            en: '/videos/CHOUKRY/srt/en.vtt',
            fr: '/videos/CHOUKRY/srt/fr.vtt',
          },
          poster: '/videos/CHOUKRY/poster.jpg',
          details: `For me, the Cisco Networking Academy is a support on which I can rely, make the content my own and progress at my own pace.`,
        },

        {
          name: 'Fabien',
          poster: '/videos/FABIEN/poster.jpg',
          path: '/videos/FABIEN/video.mp4',
          srt: {
            en: '/videos/FABIEN/srt/en.vtt',
            fr: '/videos/FABIEN/srt/fr.vtt',
          },
          details: `You can succeed if you have a dream, even if you have a disability.`,
        },

        {
          name: 'Morgane',
          path: '/videos/MORGANE/video.mp4',
          srt: {
            en: '/videos/MORGANE/srt/en.vtt',
            fr: '/videos/MORGANE/srt/fr.vtt',
          },
          poster: '/videos/MORGANE/poster.jpg',
          details: `I felt stuck in a job that I no longer really liked. That’s where I discovered the Cisco Networking Academy.`,
        },

        {
          name: 'Charlotte',
          path: '/videos/CHARLOTTE/video.mp4',
          srt: {
            en: '/videos/CHARLOTTE/srt/en.vtt',
            fr: '/videos/CHARLOTTE/srt/fr.vtt',
          },
          poster: '/videos/CHARLOTTE/poster.jpg',
          details: `Many women still think, wrongly, that IT careers are inaccessible for them.`,
        },
      ],
    },

    jobC: {
      title: 'What professions?',
      list: [
        {
          value: 'cybersecurity',
          title: 'Cybersecurity',
          img: '/imgs/jobs/header_cybersecurite.png',
          tags: [ 'Remote'],

          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Pellentesque blandit nibh eget ipsum pretium tincidunt nec vitae tortor.
        <br/><br/>
        Aliquam nisl turpis, varius ac lacus vitae, efficitur efficitur erat.
        <br/>   <br/>         
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. `,
          name: 'Cybersécurity',
        },
        {
          value: 'networking',
          title: 'Networking',
          img: '/imgs/jobs/header_networking.png',
          tags: ['Présentiel'],
          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Pellentesque blandit nibh eget ipsum pretium tincidunt nec vitae tortor.
          <br/><br/>
        Aliquam nisl turpis, varius ac lacus vitae, efficitur efficitur erat.
        <br/><br/>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. `,
          name: 'Networking',
        },
        {
          value: 'data',
          title: 'Data',
          img: '/imgs/jobs/header_data.png',
          tags: ['Présentiel'],
          details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh, at vulputate urna. Pellentesque blandit nibh eget ipsum pretium tincidunt nec vitae tortor.
          <br/><br/>
        Aliquam nisl turpis, varius ac lacus vitae, efficitur efficitur erat.
        <br/><br/>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. `,
          name: 'Datas',
        },
      ],
    },
    more: 'Learn more',
    back: 'Back',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
  },
}
