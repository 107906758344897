import { t } from 'i18next'
import { Link, useLocation } from 'react-router-dom'
import ChooseLang from '../ChooseLang/ChooseLang'
import './TopPage.scss'

export default function TopPage() {
  const location = useLocation()

  return (
    <section className="section-top top">
      <div
        className={`section_container ${
          location.pathname !== '/' && 'section_container-job'
        }`}
      >
        <img src="/imgs/element_up.svg" alt="round" className="top_imgtop" />
        <div className="topHeader">
          <div className="topHeader_back-wrapper">
            {location.pathname !== '/' && (
              <Link
                className="topHeader_back topHeader_back-desktop c-pink"
                to="/"
              >
                {`< ${t('back')}`}
              </Link>
            )}
          </div>
          {location.pathname !== '/' ? (
            <Link to="/">
              <img
                className="top_imgLogo logo topHeader_img"
                alt="logo"
                src="/imgs/levelup_logo.svg"
              />
            </Link>
          ) : (
            <img
              className="top_imgLogo logo topHeader_img"
              alt="logo"
              src="/imgs/levelup_logo.svg"
            />
          )}
          {/* <ChooseLang /> */}
          <div></div>
        </div>

        {location.pathname !== '/' && (
          <Link className="topHeader_back topHeader_back-mobile c-pink" to="/">
            {`< ${t('back')}`}
          </Link>
        )}
      </div>
    </section>
  )
}
